import i18n from 'i18next';
import { getBrandName } from '../../config';
import { ApplicationState } from '../redux/ApplicationState';
import { getBrowserResolutionBreakpointString } from './analytics.utils';
import { AnalyticsEvent, DeepPartialWithNullsAsAny } from './analyticsEvent';
import { adobeDataLayer } from './datalayer';

export enum AnalyticsPageName {
  landing,
  mainApplicantForm,
  mainApplicantSummary,
  coApplicantCheck,
  processPageSubmitted,
  processCreditRefused,
  documentUpload,
  legitimationLanding,
  legitimationSuccess,
  legitimationFailure,
  additionalDocumentUpload,
  esignLanding,
  esignSuccess,
  esignFailure,
  contactModal,
  saveContinueModal,
  tanInputModal,
  linkExpired,
  error,
  processAdditionalDocsSubmitted,
  expenses,
  expensesSummary,
  idAndAmlCheck,
  coApplicantConsentAndData,
  sanctionsList,
  odsLanding,
  odsChoice,
  applicationSubmit,
}

class Analytics {
  private initialTrackingData: AnalyticsEvent = {
    core: {
      stagingEnvironment: process.env.STAGE as any,
      dataLayerVersion: '2.13',
      category: {
        primaryCategory: 'Finance Lease',
        maturityLevel: null,
        productVariants: [],
        processType: null,
      },
      pageInfo: {
        intendedCustomerDeviceType: 'all',
        version: 'R1.0',
        releaseDate: '2022-04-15',
        language: 'en',
        market: 'AU',
        publisher: 'DU',
      },
      attributes: {
        brand: getBrandName().toUpperCase(),
        journeyType: 'customer-facing-product-journey',
      },
    },
    product: [
      {
        category: null,
        name: null,
        attributes: {
          typeOfSale: 'Online sales',
          durationUnit: 'MONTHS',
          mileageUnit: 'MILES',
        },
      },
    ],
    design: {
      browserResolutionBreakpoint: getBrowserResolutionBreakpointString(document.documentElement.clientWidth),
    },
  };

  public initTracking() {
    window.adobeDataLayer = window.adobeDataLayer || [];
    window.adobeDataLayer.push(this.initialTrackingData);
  }

  public pageNameToEvent: Record<AnalyticsPageName, AnalyticsEvent> = {
    [AnalyticsPageName.landing]: {
      core: {
        pageInfo: {
          pageName: 'Landing Page',
        },
        attributes: {
          viewChange: 'Landing Page',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
        {
          eventType: 'Product journey',
          eventAction: 'Start',
        },
        {
          eventType: 'Product configuration',
          eventAction: 'Completed',
        },
        {
          eventType: 'Product price',
          eventAction: 'Calculated',
        },
        {
          eventType: 'ProductInformation:VehicleInformation',
          eventAction: 'Provided',
        },
      ],
    },
    [AnalyticsPageName.mainApplicantForm]: {
      core: {
        pageInfo: {
          pageName: 'Application form (main applicant)',
        },
        attributes: {
          viewChange: 'Application form (main applicant)',
        },
      },
      form: {
        name: 'Personal data',
        type: 'Personal data',
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.mainApplicantSummary]: {
      core: {
        pageInfo: {
          pageName: 'Summary page (main applicant)',
        },
        attributes: {
          viewChange: 'Summary page (main applicant)',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
        {
          eventType: 'SummaryPage',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.coApplicantCheck]: {
      core: {
        pageInfo: {
          pageName: 'Co applicant check',
        },
        attributes: {
          viewChange: 'Co applicant check',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.documentUpload]: {
      core: {
        pageInfo: {
          pageName: 'Document upload',
        },
        attributes: {
          viewChange: 'Document upload',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.additionalDocumentUpload]: {
      core: {
        pageInfo: {
          pageName: 'Document upload (additional)',
        },
        attributes: {
          viewChange: 'Document upload (additional)',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.legitimationLanding]: {
      core: {
        pageInfo: {
          pageName: 'Legitimation landing page',
        },
        attributes: {
          viewChange: 'Legitimation landing page',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.legitimationSuccess]: {
      core: {
        pageInfo: {
          pageName: 'Legitimation successful',
        },
        attributes: {
          viewChange: 'Legitimation successful',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
        {
          eventType: 'Legitimation',
          eventAction: 'Success',
        },
        {
          eventType: 'Product journey',
          eventAction: 'End',
        },
      ],
    },
    [AnalyticsPageName.legitimationFailure]: {
      core: {
        pageInfo: {
          pageName: 'Legitimation failed',
        },
        attributes: {
          viewChange: 'Legitimation failed',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
        {
          eventType: 'Legitimation',
          eventAction: 'Failed',
        },
        {
          eventType: 'Product journey',
          eventAction: 'End',
        },
      ],
    },
    [AnalyticsPageName.esignLanding]: {
      core: {
        pageInfo: {
          pageName: 'eSignature landing page',
        },
        attributes: {
          viewChange: 'eSignature landing page',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
        {
          eventType: 'Journey supplement',
          eventAction: 'Start',
        },
      ],
    },
    [AnalyticsPageName.esignSuccess]: {
      core: {
        pageInfo: {
          pageName: 'eSignature success',
        },
        attributes: {
          viewChange: 'eSignature success',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
        {
          eventType: 'e-signature',
          eventAction: 'Success',
        },
        {
          eventType: 'Sale',
          eventAction: 'Success',
        },
        {
          eventType: 'Journey supplement',
          eventAction: 'End',
        },
      ],
    },
    [AnalyticsPageName.esignFailure]: {
      core: {
        pageInfo: {
          pageName: 'eSignature failed',
        },
        attributes: {
          viewChange: 'eSignature failed',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
        {
          eventType: 'e-signature',
          eventAction: 'Failed',
        },
        {
          eventType: 'Journey supplement',
          eventAction: 'End',
        },
      ],
    },
    [AnalyticsPageName.processPageSubmitted]: {
      core: {
        pageInfo: {
          pageName: 'Application form submitted',
        },
        attributes: {
          viewChange: 'Application form submitted',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
        {
          eventType: 'Sale',
          eventAction: 'Success',
        },
        {
          eventType: 'Product journey',
          eventAction: 'End',
        },
      ],
    },
    [AnalyticsPageName.processAdditionalDocsSubmitted]: {
      core: {
        pageInfo: {
          pageName: 'Document upload (additional)',
        },
        attributes: {
          viewChange: 'Document upload (additional)',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.processCreditRefused]: {
      core: {
        pageInfo: {
          pageName: 'Credit refused',
        },
        attributes: {
          viewChange: 'Credit refused',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.saveContinueModal]: {
      core: {
        attributes: {
          viewChange: 'Save application form',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.tanInputModal]: {
      core: {
        pageInfo: {
          pageName: 'Continue: Authentication',
        },
        attributes: {
          viewChange: 'TAN input',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.linkExpired]: {
      core: {
        pageInfo: {
          pageName: 'Continue: Link expired',
        },
        attributes: {
          viewChange: 'Continue: Link expired',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.contactModal]: {
      core: {
        pageInfo: {
          pageName: 'Contact',
        },
        attributes: {
          viewChange: 'Contact',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.error]: {
      core: {
        pageInfo: {
          pageName: 'Technical error',
        },
        attributes: {
          viewChange: 'Technical error',
        },
      },
      eventInfo: [
        {
          eventType: 'Error',
          eventAction: 'Technical error',
        },
      ],
    },
    [AnalyticsPageName.expenses]: {
      core: {
        pageInfo: {
          pageName: 'Income & Expense',
        },
        attributes: {
          viewChange: 'Income & Expense',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.expensesSummary]: {
      core: {
        pageInfo: {
          pageName: 'I&E Summary',
        },
        attributes: {
          viewChange: 'I&E Summary',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.idAndAmlCheck]: {
      core: {
        pageInfo: {
          pageName: 'ID & AML Page',
        },
        attributes: {
          viewChange: 'ID & AML Page',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.coApplicantConsentAndData]: {
      core: {
        pageInfo: {
          pageName: 'CoA Consent & Data',
        },
        attributes: {
          viewChange: 'CoA Consent & Data',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.sanctionsList]: {
      core: {
        pageInfo: {
          pageName: 'Sanctions List Page',
        },
        attributes: {
          viewChange: 'Sanctions List Page',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.odsLanding]: {
      core: {
        pageInfo: {
          pageName: 'ODS Landing Page',
        },
        attributes: {
          viewChange: 'ODS Landing Page',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.odsChoice]: {
      core: {
        pageInfo: {
          pageName: 'ODS/Income & Expense Choice',
        },
        attributes: {
          viewChange: 'ODS/Income & Expense Choice',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
    [AnalyticsPageName.applicationSubmit]: {
      core: {
        pageInfo: {
          pageName: 'Application Submission Page',
        },
        attributes: {
          viewChange: 'Application Submission Page',
        },
      },
      eventInfo: [
        {
          eventType: 'pageView',
          eventAction: 'Success',
        },
      ],
    },
  };

  public extendTrackingDataWithStorefront(state: ApplicationState): void {
    const { vehicleData, financialProduct } = state;
    let contractAmount =
      financialProduct.calculation.subsequentInstallmentAmount.amount * financialProduct.calculation.duration;
    if (financialProduct.calculation?.subsequentInstallmentAmount?.paymentFrequency?.toLowerCase() === 'weekly') {
      contractAmount = contractAmount * 4;
    }
    const storefrontBasedDefaults: DeepPartial<AnalyticsEvent> = {
      core: {
        attributes: {
          transactionID: state.session.id as string,
        },
      },
      product: [
        {
          category: 'Leasing',
          name: 'FinanceLease',
          vehicleModel: [
            {
              colorExterior: vehicleData.model.colorExterior,
              condition: 'new',
              descriptionLong: vehicleData.model.descriptionLong || vehicleData.model.description,
              engine: {
                transmission: vehicleData.model.transmissionType,
              },
              manufacturer: vehicleData.model.manufacturer,
            },
          ],
          attributes: {
            balloonPayment: financialProduct.calculation.balloonPayment,
            currency: financialProduct.calculation.subsequentInstallmentAmount.currency,
            downPaymentAmount: financialProduct.calculation.downPaymentAmount,
            duration: financialProduct.calculation.duration,
            effectiveInterestRate: financialProduct.calculation.effectiveInterestRate,
            grossLoanAmount: financialProduct.calculation.grossLoanAmount,
            recurringPayment: financialProduct.calculation.subsequentInstallmentAmount.amount,
            contractAmount: contractAmount,
            paymentFrequency: financialProduct.calculation.subsequentInstallmentAmount.paymentFrequency,
            yearlyMileage: financialProduct.calculation.yearlyMileage,
          },
        },
      ],
    };
    window.adobeDataLayer.push(storefrontBasedDefaults);
  }

  public extendTrackingData(data: AnalyticsEvent): void {
    window.adobeDataLayer.push(data);
  }

  public trackPage(pageName: AnalyticsPageName) {
    const eventData = this.pageNameToEvent[pageName];

    window.adobeDataLayer.push({
      error: adobeDataLayer.error,
      event: 'page',
      ...eventData,
    });

    if (pageName === AnalyticsPageName.saveContinueModal) {
      return;
    }
    this.extendTrackingData({
      core: {
        pageInfo: eventData?.core?.pageInfo,
        attributes: eventData?.core?.attributes,
      },
    });
  }

  public trackInteraction(eventInfos: AnalyticsEvent['eventInfo']) {
    const eventData: DeepPartialWithNullsAsAny<AnalyticsEvent> = {
      error: adobeDataLayer.error,
      event: 'interaction',
      eventInfo: eventInfos,
    };
    window.adobeDataLayer.push(eventData);
  }

  public trackCustomEvent(
    eventType: 'page' | 'interaction',
    eventData: DeepPartial<AnalyticsEvent>,
    extendTrackingData = false
  ) {
    window.adobeDataLayer.push({
      error: adobeDataLayer.error,
      event: eventType,
      ...eventData,
    });
    if (extendTrackingData) {
      this.extendTrackingData({
        core: eventData.core,
      });
    }
  }

  public trackFormSectionOnContinue(formSection: string) {
    const sectionLabel = i18n.t(`formsData:formSections:${formSection}:analytics:label`, { defaultValue: null });
    const linkInformation = i18n.t(`formsData:formSections:${formSection}:analytics:linkInformation`, {
      defaultValue: null,
    });
    const eventType = i18n.t(`formsData:formSections:${formSection}:analytics:eventType`, { defaultValue: null });
    if (!sectionLabel) {
      return;
    }
    const eventData: AnalyticsEvent = {
      error: adobeDataLayer.error,
      event: 'interaction',
      eventInfo: [
        {
          linkInformation: linkInformation,
          eventType: `Product Information: ${eventType}`,
          eventAction: 'Provided',
        },
      ],
      form: {
        name: 'Personal data',
        type: 'Personal data',
      },
    };
    window.adobeDataLayer.push(eventData);
  }

  public trackAPIError(error?: any) {
    const eventData: DeepPartial<AnalyticsEvent> = {
      core: {
        pageInfo: {
          pageName: 'API error',
        },
        attributes: {
          viewChange: 'API error',
        },
      },
      error: {
        errorCode: error?.response?.status ?? 'API Error',
        errorMessage: error?.response?.data?.message ?? null,
      },
      event: 'page',
      eventInfo: [
        {
          eventType: 'API',
          eventAction: 'Error',
        },
      ],
    };
    window.adobeDataLayer.push(eventData);
  }
}

export const analytics = new Analytics();

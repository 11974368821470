export const adobeDataLayer = {
  core: {
    stagingEnvironment: process.env.STAGE as string,
    dataLayerVersion: '2.13',
    pageInfo: {
      pageName: null,
      intendedCustomerDeviceType: null,
      version: null,
      releaseDate: null,
      language: null,
      market: null,
      publisher: null,
    },
    category: {
      primaryCategory: 'Leasing',
      secondaryCategory: 'FinanceLease',
      productVariants: [
        {
          name: null,
        },
      ],
      siteType: null,
      inventoryType: null,
      maturityLevel: null,
      contractType: 'New',
      processType: null,
    },
    attributes: {
      journeyType: null,
      viewChange: null,
      brand: null,
      /*
      Type: Enum
      VOLKSWAGEN
      AUDI
      BENTLEY
      CUPRA
      DUCATI
      LAMBORGHINI
      MAN
      PORSCHE
      SCANIA
      SEAT
      SKODA
      VWCV
      VWFS
      */
      contextBrand: null,
      transactionID: null,
      context: null,
      loginType: null,
      SandCtype: null,
      faqText: null,
    },
  },
  error: {
    errorCode: null,
    errorMessage: null,
    errorCausingURL: null,
  },
  universally: {
    numberOfProducts: null,
    nps: [
      {
        topic: null,
        rating: null,
      },
    ],
    additionalInformation: [] as string[],
  },
  product: [
    {
      productID: null,
      category: null,
      name: null,
      subname: [],
      productVariants: [],
      productAddons: [],
      attributes: {
        typeOfSale: null,
        paymentFrequency: null,
        currency: null,
        recurringPayment: null,
        duration: null,
        durationUnit: null,
        startDateOfContract: null,
        endDateOfContract: null,
        yearlyMileage: null,
        contractMileage: null,
        excessMileage: null,
        mileageUnit: null,
        paymentType: null,
        registrationDateOwner: null,
        preTaxSubstraction: null,
        balloonPayment: null,
        contractAmount: null,
        netLoanAmount: null,
        grossLoanAmount: null,
        downPaymentAmount: null,
        nominalInterestRate: null,
        nominalInterestAmount: null,
        effectiveInterestRate: null,
        deductible: null,
        specialAmount: null,
        addOns: [
          {
            name: null,
            additionalInformation: null,
          },
        ],
        specialConditions: null,
        termination: {
          type: null,
          reason: null,
        },
      },
      vehicleModel: [
        {
          manufacturer: null,
          name: null,
          modelVariation: null,
          year: null,
          modelLine: null,
          descriptionLong: null,
          salesID: null,
          bodyType: null,
          category: null,
          certifiedPreOwned: null,
          currentMileage: null,
          currentMileageUnit: null,
          initialRegistrationDate: null,
          basePrice_localCurrency: null,
          currency: null,
          endPrice_localCurrency: null,
          manufacturerCode: null,
          typeCode: null,
          colorExterior: null,
          typeOfUse: null,
          priorModel: null,
          equity: null,
          condition: null,
          engine: {
            fuelType: null,
            emission: null,
            transmission: null,
            enginePower: null,
          },
        },
      ],
    },
  ],
  dealerData: {
    companyId: null,
    companyName: null,
    regionId: null,
    KVPS: null,
    additionalInformation: null,
    address: {
      street: null,
      zipCode: null,
      city: null,
      state: null,
    },
  },
  form: {
    type: null,
    name: null,
    fieldValues: null,
    lastTouchedField: null,
    errorFields: null,
  },
  design: {
    browserResolutionBreakpoint: null,
  },
  customerData: {
    loginStatus: null,
    loggedInUserGroup: null,
    yearOfBirth: null,
    gender: null,
    ccResult: null,
    address: {
      zipCode: null,
    },
  },
  businessData: {
    companySize: null,
  },
  filter: [
    {
      filterName: null,
      filterAction: null,
      filterValue: [],
    },
  ],
  targeting: {
    pageTargetVersion: null,
    completeVisitPath: [],
  },
  video: {
    videoID: null,
    videoName: null,
    videoLength: null,
    videoViewed: null,
  },
  dataPrivacyStatement: {
    allowPostalAds: null,
    allowPhoneAds: null,
    allowElectronicAds: null,
    allowMailAds: null,
    allowDataTransfer: null,
    allowElectronicInvoices: null,
  },
  search: {
    term: null,
    results: null,
  },
  menuTracking: {
    breadCrumbs: [],
  },
  eventInfo: [
    {
      eventType: null,
      eventAction: null,
      eventTargetURL: null,
      linkInformation: null,
    },
  ],
  event: null,
};

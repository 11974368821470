import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Spinner } from '@vwfs-bronson/bronson-react';
import './services/i18n/i18n';

import { store } from './services/redux/root.reducer';
import { getStyles } from './services/brand';
import { getBrandName } from './config';
import { analytics } from './services/analytics/analytics';

const ConnectedApp = React.lazy(() => import('./App'));

const PositionRestore = function () {
  const location = useLocation();
  if (location.hash && location.hash !== '') {
    setTimeout(() => {
      const id = location.hash.replace('#', '');
      let element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        setTimeout(() => {
          element = document.getElementById(id);
          element?.scrollIntoView({ behavior: 'smooth' });
        }, 1000);
      }
    }, 50);
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
  return null;
};

export const Init: React.FC = function () {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    getStyles(getBrandName()).then(() => {
      setLoaded(true);
    });
  }, []);

  React.useEffect(() => {
    analytics.initTracking();
  }, []);

  return loaded ? (
    <React.Suspense fallback={<Spinner fullPage />}>
      <Provider store={store}>
        <BrowserRouter basename={process.env.REACT_APP_BASE_PATH}>
          <PositionRestore />
          <ConnectedApp />
        </BrowserRouter>
      </Provider>
    </React.Suspense>
  ) : null;
};

function render(): void {
  const rootElement = document.getElementById('root');
  ReactDOM.render(<Init />, rootElement);
}

declare let module: { hot: any };

if (module.hot) {
  module.hot.accept();
}

render();
